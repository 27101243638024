import { LoadingSpinner } from '@coles/rocket'

export interface LoaderRocketProps {
  isShown: boolean
  size?: number
}

export function LoaderRocket(props: LoaderRocketProps) {
  const size = props.size || 22
  return props.isShown ? (
    <LoadingSpinner
      data-testid='loader-rocket'
      align='center'
      ariaLabel='Loading'
      description='Just a few moments...'
      isLoading
      size={size}
      title='Loading'
    />
  ) : null
}
