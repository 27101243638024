import { Button, Modal, ModalProps } from '@coles/rocket'

export interface GenericModalRocketProps extends Omit<ModalProps, 'title'> {
  closeHandler: () => void
  showCloseButton?: boolean
  closeButtonText?: string
  titleText?: string
}

export function GenericModalRocket({
  showCloseButton,
  closeButtonText,
  titleText,
  ...props
}: GenericModalRocketProps) {
  return (
    <Modal
      title={titleText}
      footer={
        showCloseButton ? (
          <>
            <Button
              label={closeButtonText}
              onClick={() => {
                props.closeHandler()
              }}
              size='medium'
              variant='primary'
            />
          </>
        ) : undefined
      }
      {...props}
    />
  )
}
