import { Message, MessageProps } from '@coles/rocket'

export interface ThemedMessageRocketProps {
  isHidden?: boolean
  type: MessageProps['type']
  isFullWidth?: boolean
  text: string
  title: string
  hasIcon?: boolean
  hasCloseButton?: boolean
  onClose?: (event: React.SyntheticEvent) => void
}

export function ThemedMessageRocket(props: ThemedMessageRocketProps) {
  return !props.isHidden ? (
    <Message
      data-testid='themed-message-rocket'
      hasIcon={props.hasIcon ?? true}
      message={props.text ?? ''}
      name={props.title ?? ''}
      isFullWidth={props.isFullWidth ?? true}
      role='alert'
      type={props.type}
      selfClosing={props.hasCloseButton ?? true}
      onClose={props.onClose}
    />
  ) : null
}
