import { rdsText } from '@coles/rocket'
import { useEffect, useRef } from 'react'
import styled from 'styled-components'

export interface Heading1RocketProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  announceOnLoad?: boolean
}

const Heading1Styled = styled.h1<Heading1RocketProps>`
  ${rdsText.heading_600};
`

export function Heading1Rocket(props: Heading1RocketProps) {
  const titleRef: any = useRef<HTMLElement>(null)
  useEffect(() => {
    if (props.announceOnLoad && titleRef !== null) {
      titleRef.current?.focus()
    }
  }, [titleRef, props.announceOnLoad])

  return <Heading1Styled {...props} tabIndex={-1} ref={titleRef} />
}
