import { IconCheckCircleFill, theme } from '@coles/rocket'

export interface SuccessTickCircleRedRocketProps {
  size?: number
}

export function SuccessTickCircleRedRocket(
  props: SuccessTickCircleRedRocketProps
) {
  return (
    <IconCheckCircleFill
      width={props.size ? `${props.size}px` : '74px'}
      height={props.size ? `${props.size}px` : '74px'}
      color={theme.colorBackground.brand.default}
      data-testid='success-tick-circle-red-rocket'
    />
  )
}
