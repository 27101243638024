import { PasswordTextInput } from '@coles/rocket'
import { forwardRef } from 'react'
import { InputFieldRocketProps } from './InputFieldRocket'
import styled from 'styled-components'

const PasswordInputFieldRocketComponent = forwardRef<
  HTMLInputElement,
  InputFieldRocketProps
>((props, ref) => {
  const { hasError, errorText, descriptionText, labelText, ...rest } = props
  return (
    <PasswordTextInput
      error={hasError}
      label={labelText}
      description={descriptionText}
      helperText={hasError ? errorText : undefined}
      onPointerEnterCapture={null}
      onPointerLeaveCapture={null}
      fullWidth
      {...rest}
      ref={ref}
    />
  )
})

export const PasswordInputFieldRocket = styled(
  PasswordInputFieldRocketComponent
)`
  input[type='password']::-ms-reveal {
    display: none;
  }
`
