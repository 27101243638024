import { rdsText } from '@coles/rocket'
import styled from 'styled-components'

type BodyTextAdditionalProps = {
  variant?: keyof typeof rdsText
  as?: React.ElementType
}

export type BodyTextProps = React.AllHTMLAttributes<HTMLParagraphElement> &
  BodyTextAdditionalProps

export const BodyTextRocket = styled.p<BodyTextProps>`
  ${({ variant }) => rdsText[variant || 'body_200']};
`
