import React, { useEffect, useState, useRef } from 'react'
import { InputFieldNew } from './InputFieldNew'
import { InputLabelNew } from './InputLabelNew'
import { ErrorTextNew } from '../text/ErrorTextNew'
import {
  DateOfBirthContainer,
  DobInputColumnContainerDayAndMonth,
  DobInputColumnContainerYear
} from './DateOfBirthInputFieldStyles'

export interface DateOfBirthChangeHandleProps {
  newDate?: Date
  day?: number
  month?: number
  year?: number
}

export interface DateOfBirthProps {
  initialValue?: Date
  hasError?: boolean
  hasDayError?: boolean
  hasMonthError?: boolean
  hasYearError?: boolean
  changeHandler?: (dobProps: DateOfBirthChangeHandleProps) => void
  errorText?: string
  errorId?: string
  errorTestId?: string
}

export const DateOfBirthInputFieldNew = (props: DateOfBirthProps) => {
  const dayRef = useRef<HTMLInputElement>(null)
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)

  const [dayNumber, setDayNumber] = useState<string>(
    props.initialValue ? props.initialValue.getDate().toString() : ''
  )
  const [monthNumber, setMonthNumber] = useState<string>(
    props.initialValue ? (props.initialValue.getMonth() + 1).toString() : ''
  )
  const [yearNumber, setYearNumber] = useState<string>(
    props.initialValue ? props.initialValue.getFullYear().toString() : ''
  )

  const dayChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 2) {
      monthRef.current?.setSelectionRange(0, 0)
      monthRef.current?.focus()
    }

    setDayNumber(event.target.value ? event.target.value : '')
  }

  const monthChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length === 2) {
      yearRef.current?.setSelectionRange(0, 0)
      yearRef.current?.focus()
    }

    setMonthNumber(event.target.value ? event.target.value : '')
  }

  const yearChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYearNumber(event.target.value ? event.target.value : '')
  }

  const dayKeydownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.code === 'ArrowRight' &&
      dayRef.current?.selectionEnd === dayNumber.length
    ) {
      monthRef.current?.setSelectionRange(0, 1, 'forward')
      monthRef.current?.focus()
    }
  }

  const monthKeydownHandler = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (
      event.code === 'ArrowRight' &&
      monthRef.current?.selectionEnd === monthNumber.length
    ) {
      yearRef.current?.setSelectionRange(0, 1, 'forward')
      yearRef.current?.focus()
    }
    if (event.code === 'ArrowLeft' && monthRef.current?.selectionStart === 0) {
      dayRef.current?.setSelectionRange(0, 1, 'forward')
      dayRef.current?.focus()
    }
  }

  const yearKeydownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'ArrowLeft' && yearRef.current?.selectionStart === 0) {
      monthRef.current?.setSelectionRange(0, 1, 'forward')
      monthRef.current?.focus()
    }
  }

  useEffect(() => {
    const today = new Date()
    const minYear = today.getFullYear() - 120
    const parsedDayNumber = parseInt(dayNumber, 10)
    const parsedMonthNumber = parseInt(monthNumber, 10) // user inputted month number should be 1 - 12
    const parsedYearNumber = parseInt(yearNumber, 10)
    const isDateValid =
      parsedDayNumber &&
      parsedMonthNumber &&
      parsedYearNumber &&
      parsedDayNumber > 0 &&
      parsedDayNumber < 32 &&
      parsedMonthNumber > 0 &&
      parsedMonthNumber < 13 &&
      parsedYearNumber > minYear

    if (props.changeHandler) {
      props.changeHandler({
        newDate: isDateValid
          ? new Date(parsedYearNumber, parsedMonthNumber - 1, parsedDayNumber) // date constructor uses monthIndex
          : undefined,
        day: parsedDayNumber,
        month: parsedMonthNumber,
        year: parsedYearNumber
      })
    }
  }, [dayNumber, monthNumber, yearNumber])

  return (
    <React.Fragment>
      <DateOfBirthContainer>
        <DobInputColumnContainerDayAndMonth>
          <InputLabelNew htmlFor='ccp-dob-day-input'> Day </InputLabelNew>
          <InputFieldNew
            placeholder='DD'
            ref={dayRef}
            type='text'
            maxLength={2}
            pattern='[0-9]*'
            value={dayNumber}
            hasError={props.hasError || props.hasDayError}
            onChange={dayChangeHandler}
            onKeyDown={dayKeydownHandler}
            id='ccp-dob-day-input'
            inputMode='numeric'
          />
        </DobInputColumnContainerDayAndMonth>
        <DobInputColumnContainerDayAndMonth>
          <InputLabelNew htmlFor='ccp-dob-month-input'> Month </InputLabelNew>
          <InputFieldNew
            placeholder='MM'
            ref={monthRef}
            type='text'
            pattern='[0-9]*'
            maxLength={2}
            value={monthNumber}
            hasError={props.hasError || props.hasMonthError}
            onChange={monthChangeHandler}
            onKeyDown={monthKeydownHandler}
            id='ccp-dob-month-input'
            inputMode='numeric'
          />
        </DobInputColumnContainerDayAndMonth>
        <DobInputColumnContainerYear>
          <InputLabelNew htmlFor='ccp-dob-year-input'> Year </InputLabelNew>
          <InputFieldNew
            placeholder='YYYY'
            ref={yearRef}
            type='text'
            pattern='[0-9]*'
            maxLength={4}
            minLength={4}
            value={yearNumber}
            hasError={props.hasError || props.hasYearError}
            onChange={yearChangeHandler}
            onKeyDown={yearKeydownHandler}
            id='ccp-dob-year-input'
            inputMode='numeric'
          />
        </DobInputColumnContainerYear>
      </DateOfBirthContainer>
      {props.hasError && props.errorText && (
        <ErrorTextNew
          id={props.errorId}
          data-testid={props.errorTestId}
          style={{ paddingTop: '8px' }}
        >
          {props.errorText}
        </ErrorTextNew>
      )}
    </React.Fragment>
  )
}
