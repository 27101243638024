import { Button, ButtonProps } from '@coles/rocket'

export interface ThemedButtonRocketProps
  extends Omit<ButtonProps, 'variant' | 'size'> {
  isSecondary?: boolean
  size?: ButtonProps['size']
}

export const ThemedButtonRocket = (props: ThemedButtonRocketProps) => {
  const { isSecondary, size, ...rest } = props
  return (
    <Button
      {...rest}
      size={size || 'large'}
      variant={isSecondary ? 'secondary' : 'primary'}
    />
  )
}
