import { ColesClientName, getThemeByClientName } from './ColesThemes'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as RocketThemeProvider } from '@coles/rocket'
import { ClientContextProvider } from './ClientContext'

export interface ICCPThemeProps {
  clientName: ColesClientName
  children?: any
  withRocket?: boolean
}

export function CCPTheme(props: ICCPThemeProps) {
  const theme = getThemeByClientName(props.clientName)
  return props.withRocket ? (
    <ThemeProvider theme={theme}>
      <ClientContextProvider value={props.clientName}>
        <RocketThemeProvider>{props.children}</RocketThemeProvider>
      </ClientContextProvider>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={theme}>
      <ClientContextProvider value={props.clientName}>
        {props.children}
      </ClientContextProvider>
    </ThemeProvider>
  )
}
