import { IconCloseCircleOutline, theme } from '@coles/rocket'

export interface ErrorCrossCircleRocketProps {
  size?: number
  id?: string
}

export function ErrorCrossCircleRocket(props: ErrorCrossCircleRocketProps) {
  const height = props.size ? `${props.size}px` : '74px'
  const width = props.size ? `${props.size}px` : '74px'

  return (
    <IconCloseCircleOutline
      width={width}
      height={height}
      data-testid='error-cross-circle-rocket'
      color={theme.colorBackground.brand.hover}
      {...props}
    />
  )
}
