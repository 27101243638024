import { TextInput } from '@coles/rocket'
import React, { InputHTMLAttributes } from 'react'

export interface InputFieldRocketProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'color'> {
  id: string
  placeholder?: string
  hasError?: boolean
  labelText?: string
  descriptionText?: string
  helperText?: string
  errorText?: string
  size?: 'small' | 'medium'
  'data-testid'?: string
}

export const InputFieldRocket = React.forwardRef<
  HTMLInputElement,
  InputFieldRocketProps
>((props, ref) => {
  const {
    hasError,
    errorText,
    descriptionText,
    labelText,
    helperText,
    ...rest
  } = props
  return (
    <TextInput
      error={hasError}
      label={labelText}
      description={descriptionText}
      helperText={hasError ? errorText : helperText}
      fullWidth
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      {...rest}
      ref={ref}
    />
  )
})
