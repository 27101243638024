import { createContext, useContext } from 'react'
import { ColesClientName, getThemeByClientName } from './ColesThemes'

type ClientContextValue = ColesClientName | ''

const ClientContext = createContext<ClientContextValue>('')

const useClientContext = () => {
  const currentClientName = useContext(ClientContext)
  const currentClientConsumer = getThemeByClientName(
    currentClientName as ColesClientName
  ).consumer
  return {
    clientName: currentClientName,
    consumer: currentClientConsumer
  }
}

const ClientContextProvider = ClientContext.Provider

export { ClientContextProvider, useClientContext }
