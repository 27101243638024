import { IconCheckCircleOutline, theme } from '@coles/rocket'

export interface SuccessTickCircleRocketProps {
  size?: number
}

export const SuccessTickCircleRocket = (
  props: SuccessTickCircleRocketProps
) => (
  <IconCheckCircleOutline
    width={props.size ? `${props.size}px` : '74px'}
    height={props.size ? `${props.size}px` : '74px'}
    color={theme.colorFeedback.success.primary.default}
  />
)
