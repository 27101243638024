import { Autocomplete, AutocompleteProps } from '@coles/rocket'

export interface AutoSuggestOption {
  value: any
  label: string
}

export interface AutocompleteInputFieldRocketProps
  extends Omit<
    AutocompleteProps,
    'description' | 'label' | 'errorMsg' | 'options'
  > {
  hasError?: boolean
  labelText?: string
  descriptionText?: string
  errorId?: string
  errorTestId?: string
  errorText?: string
  options: AutoSuggestOption[]
}

export const AutocompleteInputFieldRocket = (
  props: AutocompleteInputFieldRocketProps
) => {
  const { hasError, errorText, descriptionText, labelText, ...rest } = props
  const defaultEqualityCheck = (option: any, value: any) =>
    option && value && option.value === value.value
  return (
    <Autocomplete
      errorMsg={hasError ? errorText : undefined}
      title={labelText}
      description={descriptionText}
      fullWidth
      smBorderRadius
      filterOptions={undefined} // Filter options when user types in input
      isOptionEqualToValue={defaultEqualityCheck}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      {...rest}
    />
  )
}
