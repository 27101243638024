import { IconUnlockOutline } from '@coles/rocket'

export interface SecureLoginIconRocketProps {
  size?: number
}

export function SecureLoginIconRocket(props: SecureLoginIconRocketProps) {
  const { size, ...rest } = props

  return (
    <IconUnlockOutline
      width={size ? `${size}px` : '16px'}
      height={size ? `${size}px` : '18px'}
      data-testid='secure-login-icon-rocket'
      {...rest}
    />
  )
}
