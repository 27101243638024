import { IconEmailOutline } from '@coles/rocket'

export interface MailIconRocketProps {
  size?: number
}

export const MailIconRocket = (props: MailIconRocketProps) => {
  return (
    <IconEmailOutline
      width={props.size ? `${props.size}px` : '18px'}
      height={props.size ? `${props.size}px` : '18px'}
    />
  )
}
