import { SelectDropdown, SelectDropdownProps } from '@coles/rocket'

export interface SelectDropdownOption {
  value: any
  label: string
}

export interface DropdownInputFieldRocketProps
  extends Omit<
    SelectDropdownProps,
    'description' | 'label' | 'errorMsg' | 'options'
  > {
  descriptionText?: string
  options: SelectDropdownOption[]
  hasError?: boolean
  errorText?: string
}

export const DropdownInputFieldRocket = (
  props: DropdownInputFieldRocketProps
) => {
  const { descriptionText, hasError, errorText, ...rest } = props
  return (
    <SelectDropdown
      id='select-dropdown'
      description={descriptionText}
      error={hasError}
      helperText={errorText}
      fullWidth
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
      {...rest}
    />
  )
}
