import styled from 'styled-components'
import {
  GenericModalRocket,
  GenericModalRocketProps
} from './GenericModalRocket'
import { rdsText } from '@coles/rocket'

const TermsConditionsListitem = styled.li`
  font-weight: bold;
  padding-bottom: 14px;
`

const TermsConditionsOrderedList = styled.ol`
  font-weight: normal;
  margin: 0;
  margin-left: 2rem;
`

const TermsConditionsAlphabeticalList = styled.ol``
const TermsConditionsUnorderedList = styled.ul`
  padding-left: 20px;
`

const TermsConditionsAlphabeticalListItem = styled.li`
  &:before {
    content: '(' counter(item, lower-alpha) ')' !important;
  }
`

const TermsConditionsUnorderedListItem = styled.li`
  &:before {
    content: counter(item, disc) ' ' !important;
  }
`
const TermsAndConditionsContentDiv = styled.div`
  ${rdsText.body_300};
  font-family: 'Source Sans', sans-serif;
  ol li {
    display: block;
  } /* hide original list counter */
  ol > li:first-child {
    counter-reset: item;
  } /* reset counter */
  ol > li {
    counter-increment: item;
    position: relative;
  } /* increment counter */
  ol > li:before {
    content: counters(item, '.') '. ';
    position: absolute;
    margin-right: 100%;
    right: 10px;
  }
`

const TermsConditionsSublistItem = styled.li``

const TermsConditionsContentTitle = styled.span``

function MembershipContent() {
  return (
    <TermsConditionsListitem>
      <TermsConditionsContentTitle>Membership</TermsConditionsContentTitle>
      <TermsConditionsOrderedList>
        <TermsConditionsSublistItem>
          You may register for a Coles Group account if you are aged 16 years or
          over and have a current Australian residential address. To create an
          account, you must register online and complete the Coles Group account
          registration form.
        </TermsConditionsSublistItem>
      </TermsConditionsOrderedList>
    </TermsConditionsListitem>
  )
}

function ObligationsContent() {
  return (
    <TermsConditionsListitem>
      <TermsConditionsContentTitle>
        Your Obligations
      </TermsConditionsContentTitle>
      <TermsConditionsOrderedList>
        <TermsConditionsSublistItem>
          By creating a Coles Group account in accordance with 1.1 above
          constitutes acceptance of these terms and conditions.
        </TermsConditionsSublistItem>
        <TermsConditionsSublistItem>
          It is your responsibility to inform us if:
          <TermsConditionsOrderedList>
            <TermsConditionsSublistItem>
              Your personal details change (including, but not limited to, your
              email address or phone number). If we do not have your correct
              details, we may not be able to contact you about your account or
              send you offers.
            </TermsConditionsSublistItem>
            <TermsConditionsSublistItem>
              You would like your details removed from the account.
            </TermsConditionsSublistItem>
          </TermsConditionsOrderedList>
        </TermsConditionsSublistItem>
        <TermsConditionsSublistItem>
          You must ensure that your login credential (email address) and
          password that is used to access your Coles Group account and the
          details of your account are kept in a safe and secure manner.
        </TermsConditionsSublistItem>
      </TermsConditionsOrderedList>
    </TermsConditionsListitem>
  )
}

function PrivacyAndCommercialElectronicMessagesContent() {
  return (
    <TermsConditionsListitem>
      <TermsConditionsContentTitle>
        Privacy and Commercial Electronic Messages
      </TermsConditionsContentTitle>
      <TermsConditionsOrderedList>
        <TermsConditionsSublistItem>
          The Coles Group Privacy Policy forms part of these Terms and
          Conditions. The Coles Group Privacy Policy sets out what personal
          information we collect, how we use it, to whom we may disclose it and
          what your privacy choices are. Our Privacy Policy is available on the
          Coles Group website.
        </TermsConditionsSublistItem>

        <TermsConditionsSublistItem>
          Coles Group companies and divisions that support use of a Coles Group
          account will be able to communicate with you about their products and
          services. This includes sending you great offers and promotions.
          Unless you have opted out, you agree to us communicating with you via
          various channels and media (including by email, SMS, phone, mail, app
          notifications and by advertising on certain websites and social
          media).
        </TermsConditionsSublistItem>

        <TermsConditionsSublistItem>
          You can choose to opt out of some types of marketing and promotional
          messages that we send you. You can do this by:
          <TermsConditionsAlphabeticalList>
            <TermsConditionsAlphabeticalListItem>
              For SMSs and emails:
              <TermsConditionsUnorderedList>
                <TermsConditionsUnorderedListItem>
                  clicking unsubscribe in any marketing or promotional emails
                  that we send you;
                </TermsConditionsUnorderedListItem>
                <TermsConditionsUnorderedListItem>
                  sending “STOP” to the number we provide you on any of our
                  marketing or promotional SMSs
                </TermsConditionsUnorderedListItem>
              </TermsConditionsUnorderedList>
            </TermsConditionsAlphabeticalListItem>

            <TermsConditionsAlphabeticalListItem>
              For in-app notifications in the Coles App and advertising on
              certain websites and social media:
              <TermsConditionsUnorderedList>
                <TermsConditionsUnorderedListItem>
                  turning off notifications on your mobile device or changing
                  your privacy settings online so you no longer receive app
                  notifications or targeted advertising.
                </TermsConditionsUnorderedListItem>
              </TermsConditionsUnorderedList>
            </TermsConditionsAlphabeticalListItem>
          </TermsConditionsAlphabeticalList>
        </TermsConditionsSublistItem>

        <TermsConditionsSublistItem>
          There are, however, some messages that we must be able to send you.
          These include, for example, messages relating to your account,
          forgotten passwords, updates to our T&amp;Cs and Privacy Policy.
        </TermsConditionsSublistItem>

        <TermsConditionsSublistItem>
          If you wish to update where we send the above communications to you
          under clause 3.3(a), you can update your contact details by logging
          into your Coles Group account. Please note that if you update your
          contact details, it may take up to 5 business days before
          communications we send to you change from your previous contact
          details to your updated contact details. Opting out of receiving
          commercial electronic messages from your Coles Group account will not
          withdraw your consent to receive messages from other Coles Group
          brands. Any consent you have provided to receive commercial electronic
          messages from other Coles Group brands are governed under separate
          terms and conditions that apply to those other Coles Group brands. If
          you would like to opt out of other Coles Group brands, please follow
          the opt out facilities in their communications or other opt out
          options set out in their terms and conditions.
        </TermsConditionsSublistItem>
      </TermsConditionsOrderedList>
    </TermsConditionsListitem>
  )
}

function RestrictionsContent() {
  return (
    <TermsConditionsListitem>
      <TermsConditionsContentTitle>Restrictions</TermsConditionsContentTitle>
      <TermsConditionsOrderedList>
        <TermsConditionsSublistItem>You must not:</TermsConditionsSublistItem>
        <TermsConditionsOrderedList>
          <TermsConditionsSublistItem>
            Use another person’s details without their permission or impersonate
            another person when accessing a Coles Group account;
          </TermsConditionsSublistItem>
          <TermsConditionsSublistItem>
            Knowingly transmit any viruses, worms, defects, trojan horses or
            similar disabling or malicious code;
          </TermsConditionsSublistItem>
          <TermsConditionsSublistItem>
            Use any robot, spider, site search and retrieval application or
            other mechanism to retrieve or index any portion of the Coles Group
            account site;
          </TermsConditionsSublistItem>
          <TermsConditionsSublistItem>
            Create accounts by automated means or under false or fraudulent
            pretences;
          </TermsConditionsSublistItem>
          <TermsConditionsSublistItem>
            Take any action that imposes or that would, in our reasonable
            opinion, result in an unreasonable or disproportionately large load
            on our infrastructure;
          </TermsConditionsSublistItem>
          <TermsConditionsSublistItem>
            Use your Coles Group account other than in accordance with these
            terms and conditions.
          </TermsConditionsSublistItem>
        </TermsConditionsOrderedList>
      </TermsConditionsOrderedList>
    </TermsConditionsListitem>
  )
}

function GeneralContent() {
  return (
    <TermsConditionsListitem>
      <TermsConditionsContentTitle>General</TermsConditionsContentTitle>
      <TermsConditionsOrderedList>
        <TermsConditionsSublistItem>
          We may, in our discretion, make any change we see fit to these Terms
          and Conditions.
        </TermsConditionsSublistItem>

        <TermsConditionsSublistItem>
          We will give you notice of any change to the Terms and Conditions, at
          our sole discretion:
          <TermsConditionsOrderedList>
            <TermsConditionsSublistItem>
              Via the Coles Group account website; and/or
            </TermsConditionsSublistItem>
            <TermsConditionsSublistItem>
              By direct communications sent to registered account holders.
            </TermsConditionsSublistItem>
          </TermsConditionsOrderedList>
        </TermsConditionsSublistItem>

        <TermsConditionsSublistItem>
          We reserve the right to refuse service, terminate accounts and/or
          remove or edit content if we, acting reasonably, deem that you have
          acted in breach of these terms and conditions or have used your Coles
          Group account in a fraudulent or improper manner.
        </TermsConditionsSublistItem>

        <TermsConditionsSublistItem>
          Nothing in these Terms and Conditions limits your rights under the
          Australian Consumer Law. In these Terms and Conditions "we", "us", or
          "our" means Coles Group Limited (ABN 11 004 089 936) or its authorised
          representatives. To contact us, please call Coles Customer Care on
          1800 061 562.
        </TermsConditionsSublistItem>
      </TermsConditionsOrderedList>
    </TermsConditionsListitem>
  )
}

function TermsAndConditionsContent() {
  return (
    <TermsAndConditionsContentDiv>
      <TermsConditionsOrderedList>
        <MembershipContent />
        <ObligationsContent />
        <PrivacyAndCommercialElectronicMessagesContent />
        <RestrictionsContent />
        <GeneralContent />
      </TermsConditionsOrderedList>
    </TermsAndConditionsContentDiv>
  )
}

export function TermsAndConditionsModalRocket(props: GenericModalRocketProps) {
  return (
    <GenericModalRocket {...props}>
      <TermsAndConditionsContent />
    </GenericModalRocket>
  )
}
