import styled from 'styled-components'
import { BodyTextRocket } from '../../text/BodyTextRocket'
import { LinkRocket } from '../../text/LinkRocket'
import {
  GenericModalRocket,
  GenericModalRocketProps
} from './GenericModalRocket'

const HREF_COLES_CONTACT_US =
  'http://www.coles.com.au/customer-service/contact-us'

const HREF_COLES_PRIVACY_POLICY =
  'https://www.coles.com.au/important-information/privacy-policy'

const CollectionContentContainer = styled.div`
  padding: 0;
`

const CollectionParagraph = styled(BodyTextRocket)`
  padding-bottom: 8px;
`

export function CollectionNoticeModalRocket(props: GenericModalRocketProps) {
  return (
    <GenericModalRocket {...props}>
      <CollectionContentContainer>
        <CollectionParagraph>
          We are collecting your personal information so you can use our online
          ordering service, and to enable us and our marketing partners to
          better understand your preferences in order to personalise your
          experience and serve you better, to tell you about products, services
          and offers we and our marketing partners think might interest you
          (which may include third party products, services and offers), and to
          inform the activities described in our privacy policy.
        </CollectionParagraph>
        <CollectionParagraph>
          We may engage a third-party provider to fulfill your order delivery.
          Our delivery partners may have their own information collection
          practices, including requiring you to provide personal information to
          verify your age and identity at the point of delivery. We will not
          otherwise disclose the personal information provided by you unless
          required or authorised by law or in accordance with our privacy
          policy.
        </CollectionParagraph>
        <CollectionParagraph>
          Without your personal information, we may not be able to provide you
          with our service or offer access to all available delivery timeframes
          and styles. For example, when you place an order, we need to collect
          and share your name, address, and contact details with our delivery
          partners.
        </CollectionParagraph>
        <CollectionParagraph>
          Our{' '}
          <LinkRocket
            href={HREF_COLES_PRIVACY_POLICY}
            size='small'
            variant='primary'
            label='privacy policy'
            openInNewTab
          />{' '}
          explains more about the types of personal information we collect, how
          we collect, use and disclose it and how you can access and/or correct
          it, or make a privacy complaint.
        </CollectionParagraph>
        <CollectionParagraph>
          Questions or concerns? Contact Email (via webform):{' '}
          <LinkRocket
            href={HREF_COLES_CONTACT_US}
            size='small'
            variant='primary'
            label='Customer Care - Contact us | Coles'
            openInNewTab
          />{' '}
          or 1800 061 562.
        </CollectionParagraph>
      </CollectionContentContainer>
    </GenericModalRocket>
  )
}
